/**
 * 示例-编辑页-带栏目标题
 * luxinwen
 * 2023-01
 */
<template>
  <div class="page">
    <div class="page-main">
      <div class="page-main-content">
        <Form ref="editForm" :model="formData" :rules="formRule" :label-width="150">
          <div class="page-main-content-thead">基本信息</div>
          <FormItem label="商品名称" prop="name">
            <Input v-model.trim="formData.name" class="width-l" maxlength="30" clearable />
          </FormItem>
          <FormItem label="分享描述" prop="code">
            <Input v-model.trim="formData.code" class="width-l" maxlength="36" clearable />
            <p class="text-tips">微信分享给好友时会显示，建议36个字以内</p>
          </FormItem>
          <FormItem label="商品状态" prop="status">
            <Select v-model="formData.status" class="width-s" clearable>
              <Option v-for="(value, key) in statusList" :key="'status' + key" :value="key">{{ value }}</Option>
            </Select>
          </FormItem>
          <div class="page-main-content-thead">价格库存</div>
          <FormItem label="价格" prop="price">
            <InputNumber v-model="formData.price" :min="0" :max="10000000" />
          </FormItem>
          <FormItem label="库存" prop="price">
            <InputNumber v-model="formData.price" :min="0" :max="10000000" />
            <p class="padding-t-10">
              <Checkbox v-model="formData.bool">商品详情不显示剩余件数</Checkbox>
            </p>
          </FormItem>
          <div class="page-main-content-thead">其它信息</div>
          <FormItem label="快递运费">
            <RadioGroup v-model="formData.freight">
              <div>
                <Radio label="1">
                  <span>统一邮费</span>
                </Radio>
                <InputNumber v-model="formData.price" :min="0" :formatter="value => `¥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')" :parser="value => value.replace(/\$\s?|(,*)/g, '')" />
              </div>
              <div>
                <Radio label="2">
                  <span>邮费模板</span>
                </Radio>
                <Select v-model="formData.status" class="width-s" placeholder="请选择运费模板" clearable>
                  <Option v-for="(value, key) in statusList" :key="'mb' + key" :value="key">{{ value }}</Option>
                </Select>
              </div>
            </RadioGroup>
            <p class="text-tips">运费模版支持按地区设置运费，按购买件数计算运费，按重量计算运费等</p>
          </FormItem>
          <FormItem label="上架时间">
            <RadioGroup v-model="formData.freight">
              <div>
                <Radio label="1">
                  <span>立即上架售卖</span>
                </Radio>
              </div>
              <div>
                <Radio label="2">
                  <span>自定义上架时间</span>
                </Radio>
                <DatePicker v-model="formData.time" class="width-s" type="datetime" placeholder="请选择上架售卖时间" />
              </div>
              <div>
                <Radio label="3">
                  <span>暂不售卖，放入仓库</span>
                </Radio>
              </div>
            </RadioGroup>
          </FormItem>
        </Form>
      </div>
    </div>
    <sp-footer>
      <Button type="primary" :loading="btnLoading" @click="submit">提交</Button>
      <Button @click="cancel">返回</Button>
    </sp-footer>
  </div>
</template>

<script>
  import { TEST_STATUS } from '@/util/enum';

  export default {
    data() {
      return {
        pageFlag: 'add',    // 页面状态，可选值为 add（新增），edit（编辑），show（查看）
        dataId: '',         // 数据ID
        btnLoading: false,  // 按钮状态
        formData: {
          name: '',
          code: '',
          status: '',
          price: null,
          bool: false,
          freight: '1',
          time: ''
        },
        formRule: {
          name: [
            {
              required: true,
              message: '请输入商品名称',
              trigger: 'blur'
            }
          ],
          code: [
            {
              required: true,
              message: '请输入分享描述',
              trigger: 'blur'
            }
          ],
          status: [
            {
              required: true,
              message: '请选择商品状态',
              trigger: 'change'
            }
          ],
          price: [
            {
              type: 'number',
              required: true,
              message: '请输入价格',
              trigger: 'blur'
            }
          ]
        },
        statusList: TEST_STATUS
      };
    },
    created() {
      if (this.$route.name.indexOf('Edit') !== -1) {
        this.pageFlag = 'edit';
        this.dataId = this.$route.query.id;
      } else if (this.$route.name.indexOf('Show') !== -1) {
        this.pageFlag = 'show';
        this.dataId = this.$route.query.id;
      }
      if (this.dataId) {
        this.getData();
      }
    },
    methods: {
      /**
       * 获取数据
       */
      getData() {
        this.$axios({
          url: this.$api.test.queryTestDetail,
          mock: true,
          data: {
            id: this.dataId
          },
          loading: true
        }).then(data => {
          Object.keys(this.formData).forEach(item => {
            if (data.hasOwnProperty(item)) {
              this.formData[item] = data[item];
            }
          });
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.test.addTest;
              let params = Object.assign({}, this.formData);
              if (this.pageFlag === 'edit' && this.dataId) {
                url = this.$api.test.updateTest;
                params.id = this.dataId;
              }
              this.save(url, params);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.btnLoading = true;
          },
          complete: () => {
            this.btnLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.$router.push({
            name: 'testList'
          });
        });
      },
      /**
       * 返回
       */
      cancel() {
        this.$router.go(-1);
      }
    }
  };
</script>